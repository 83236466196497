import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import booki from "../../assets/projects/booki.png";
import gameon from "../../assets/projects/gameon.png";
import hottakes from "../../assets/projects/hottakes.png";
import kanap from "../../assets/projects/kanap.png";
import kasa from "../../assets/projects/kasa.png";
import miaouuu from "../../assets/projects/miaouuu.png";
import ohmyfood from "../../assets/projects/ohmyfood.png";
import panthere from "../../assets/projects/panthere.png";
import socketchat from "../../assets/projects/socketchat.png";
import "../../pages/style.scss";
import ProjectCard from "./ProjectCard";

function Projectlist() {
  return (
    <div className="project">
      <Container fluid className="project-section">
        <Container>
          <Row>
            <Col>
              <h2 className="headtext">Réalisations</h2>
              <p className="infostext">
                Vous trouverez ci-dessous quelques-unes de mes réalisations.
              </p>
              <p>
                Parmi ces réalisations figurent des projets réalisés dans le
                cadre de ma formation, mais également certains projets
                personnels m'ayant permis la découverte ainsi que
                l'apprentissage de nouvelles technologies.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={miaouuu}
                title="Miaouuu"
                description="Boutique en ligne en cours de réalisation avec Wordpress et WooCommerce sur l'univers du chat."
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={gameon}
                title="GameOn"
                description="Projet OpenClassRooms. L'objectif étant de reproduire une maquette figma. Le site devait être responsive et la gestion de la version tablette devait être improvisée."
                ghLink="https://kind2l.github.io/gameon/"
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={booki}
                title="Booki"
                description="Projet OpenClassRooms. L'objectif était de reproduire une maquette figma à l'identique, en utilisant du HTML et CSS."
                ghLink="https://kind2l.github.io/booki/"
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={ohmyfood}
                title="OhMyFood"
                description="Projet OpenClassRooms. L'objectif était de reproduire d'une maquette figma avec une approche mobile-first, mais également de réaliser les animations requises dans le cahier des charges."
                ghLink="https://kind2l.github.io/ohmyfood/"
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={socketchat}
                title="Socket Chat"
                description="Un chat en temps réel avec en plus l'intégration d'une IA. Réalisé avec Node.js, socket.io, l'API easy-peasy et une base de données SQLite."
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={panthere}
                title="La Panthère"
                description="Projet OpenClassRooms. L'objectif était de réaliser une analyse du SEO d'un site existant, de rédiger un compte-rendu de celui-ci, puis de proposer une version optimisée accompagnée d'un rapport d'optimisation."
                ghLink="https://kind2l.github.io/lapanthere/"
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={kanap}
                title="Kanap"
                description="Projet OpenClassRooms. L'objectif était d'établir une connexion entre la partie client et une API, de créer les fonctions pour chacune des pages et de mettre en place un panier stocké en local."
              />
            </Col>
            {/* <Col md={4} className="project-card">
              <ProjectCard
                imgPath={kindll}
                title="Kindll"
                description="Projet personnel qui est en cours de création."
                ghLink="http://kindll.fr/"
              />
            </Col> */}
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={hottakes}
                title="HotTakes"
                description="Projet OpenClassRooms. Réalisation d'une API, association avec la partie front et utilisation d'une base de données non-relationnelles avec MongoDB."
              />
            </Col>
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={kasa}
                title="Kasa"
                description="Projet OpenClassRooms. Reproduction d'une maquette Figma en utilisant la librairie React et en faisant des requêtes auprès d'une API. Création d'un diaporama sans l'aide d'une librairie tiers, ainsi que d'un hook personnalisé."
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default Projectlist;
