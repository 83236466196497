import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function ProjectCard(props) {
  console.log(props.ghLink);

  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Title>{props.title}</Card.Title>
      <Card.Body>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        {props.ghLink ? (
          <Button
            className="viewbtn"
            variant="primary"
            href={props.ghLink}
            target="_blank"
          >
            {`Découvrir`}
          </Button>
        ) : (
          <Button
            className="viewbtn"
            variant="primary"
            href={props.ghLink}
            target="_blank"
            disabled="true"
          >
            {`Non disponible`}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCard;
