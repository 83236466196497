import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home.js";
import Project from "./pages/Project";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/project" element={<Project />}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
